<template>
  <div class="mt-50 cartel-main ma-auto">
    <div
		v-show="is_view"
		class="mt-50 flex-row justify-space-between " style="max-height: 980px;"
	>
      <SaleList
          v-show="is_view"
          :user="user"
          class="box-side mr-30 scrollBar"

          @click="setItem"
      ></SaleList>

      <SaleDetail
          v-if="is_view"
          :user="user"
          :item="item"

          class="box-items-list mr-30"

          @setDetail="setDetail"
      ></SaleDetail>

      <SalePayment
          v-if="is_view"
          :user="user"
          :item="item"
          :detail="item_detail"


          style="width: 290px;"
      ></SalePayment>
    </div>
    <Empty
      v-show="!is_view"
	></Empty>
  </div>
</template>
<script>

import SaleList from "@/view/Launchpad/SaleList";
import SaleDetail from "@/view/Launchpad/SaleDetail.vue";
import SalePayment from "@/view/Launchpad/SalePayment.vue";
import Empty from "@/view/Layout/Empty";

export default {
  name: 'SaleMain'
  , components: {Empty, SalePayment, SaleDetail, SaleList}
  , props: ['user']
  , data: function(){
    return {
      program: {
        name: 'launchpad'
        , title: '런치패드'
        , type: 'launchpad'
        , bg_contents: 'bg-gray01'
        , banner: true
      }
      , max: false
      , is_add: true
      , item: {}
      , item_detail: {}
      , is_view: false
    }
  }
  , methods: {
    getData: async function(){
      try{
        this.$bus.$emit('on', true)
        this.is_add = false
        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.get_cartel_main_info
          , data: {
            member_number: this.user.member_number
            , cartl_list_page: {
              page_number: this.item_search.page_number
              , pagerecnum: this.item_search.pagerecnum
            }
            , nrt_post_list_page: {
              page_number: this.item_search.post.page_number
              , pagerecnum: this.item_search.post.pagerecnum
            }
          }
          , type: true
        })

        if(result.success){
          this.items_my_cartel = result.data.member_cartl_list

          if(this.item_search.post.page_number == 1){
            this.items_bbs = result.data.entry_cartl_nrt_post_list
          }else{
            this.items_bbs = this.items_bbs.concat(result.data.entry_cartl_nrt_post_list)
          }

          if(result.data.entry_cartl_nrt_post_list.length > 0){
            this.max =false
          }else{
            this.max = true
          }

          setTimeout( () => {
            let type = 'recomm'
            let t = this.$refs["box-" + type]?.scrollWidth

            this.item_slide[type].tp = Math.ceil(t / this.item_slide[type].d)
          }, 100)
        }else{
          throw result.message
        }
      }catch (e) {
        this.$log.console(e)
        this.$bus.$emit('notify', { type: 'error', message: e})
      }finally {
        this.$bus.$emit('on', false)
        this.is_add = true
      }
    }
    , toDetail: function(item){
      this.$bus.$emit('to', { name: 'detail', child: 'detail', params: {idx: item.cartl_number, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})
    }
    , setItem: function (item) {
        console.log(item)
		if(item && item.cartl_nft_sl_number){
          this.item = item
          this.is_view = true
		}
    }
    , setDetail: function (item) {
      this.item_detail = item
    }
  }
  , async created() {
    this.$emit('onLoad', this.program)
  }
}
</script>

<style scoped>
.scrollBar {
  overflow-y: scroll;
  padding-right: 5px;
}

/* 아래의 모든 코드는 영역::코드로 사용 */
.scrollBar::-webkit-scrollbar {
  width: 5px;  /* 스크롤바의 너비 */
}

.scrollBar::-webkit-scrollbar-thumb {
  height: 25%; /* 스크롤바의 길이 */
  background: #c2c2c2; /* 스크롤바의 색상 */

  border-radius: 10px;
}

.scrollBar::-webkit-scrollbar-track {
  background: #f7f7f7;  /*스크롤바 뒷 배경 색상*/
}
</style>