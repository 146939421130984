<template>
  <div
      class="section_wrap"
      ref="post_list_item_view"
  >
    <ul v-if="items.length > 0"
        class="container flex-column justify-center w-100"
    >
      <li class="mb-20 minting_card"
          v-for="(item, index) in items"
          :key="'item_' + index"
          :style="'background-image: url(' + item.sl_img_file_path + ');'"
          :class="{ 'select_border' : item.cartl_nft_sl_number === detail_item.cartl_nft_sl_number }"
          @click="goDetail(item)"
      >
        <div class="minting_bg">
          <div>
            <span style="color: white; font-size: 13px;">Date. {{ item.sl_startdt }} ~ {{ item.sl_enddate }}</span>
          </div>
          <div class="flex justify-space-between">
            <div></div>
            <button
                v-if="item.sl_state_code === 'SA00200001' || item.sl_state_code === 'SA00200002'"
                style="color: white; border: 1px solid white; padding: 5px 11px; border-radius: 20px; font-size: 12px;  opacity: 0.6;"
            >
              COMING SOON
            </button>
            <button
                v-if="item.sl_state_code === 'SA00200003'"
                style="color: white; border: 1px solid white; padding: 5px 11px; border-radius: 20px; font-size: 12px"
            >
              참여하기
            </button>
            <button
                v-if="item.sl_state_code === 'SA00200004'"
                style="color: white; border: 1px solid white; padding: 5px 11px; border-radius: 20px; font-size: 12px; opacity: 0.6;"
            >
              판매종료
            </button>
            <button
                v-if="item.sl_state_code === 'SA00200005'"
                style="color: white; border: 1px solid white; padding: 5px 11px; border-radius: 20px; font-size: 12px;  opacity: 0.6;"
            >
              SOLD OUT
            </button>
          </div>
        </div>
        <div class="minting_text">
          <p class="size-px-12" style="color: #555;">{{ item.cartl_name }}</p>
          <p class="size-px-16 font-weight-700">{{ item.sl_name }}</p>
          <div class="flex-row">
            <h5 class="size-px-13 font-weight-700" style="color: #3E47B7">&#x20A9;{{ Number(item.sl_price) }}</h5>
          </div>
        </div>
      </li>
    </ul>
    <div
        v-else
        class="character3 pt-130 text-center size-px-16 color-white full-height" style="margin-top: 50%"
    >판매중인 NFT 내역이 없습니다.
    </div>
  </div>
</template>

<script>
export default {
  name: 'SaleList'
  , props: ['user']
  , data: function () {
    return {
      items: []
      , detail_item: []
    }
  }
  , methods: {
    getData: async function () {
      try {
        this.$bus.$emit('on', true)
        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.get_sale_nft_list
          , data: {
            member_number: this.user.member_number
          }
          , type: true
        })

        if (result.success) {
          this.items = result.data.cartl_nft_sl_list
          this.goDetail(this.items[0])
        } else {
          throw result.message
        }

      } catch (e) {
        this.$bus.$emit('notify', {type: 'success', message: e, config: {bottom: 0}})
      } finally {
        this.$bus.$emit('on', false)
      }
    }
    , goDetail(item) {
      this.detail_item = item
      this.$emit('click', item)
    }
  }
  , created() {
    this.getData()
  }
}

</script>

<style scoped>
button:disabled {
  background-color: transparent;
}

.select_border {
  border: 2px solid #3E47B7;
}

.minting_card {
  width: 100%;
  height: 240px;
  max-width: 42.5rem;
  border-radius: 1.5rem;
}

.minting_bg {
  width: 100%;
  height: 55%;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.minting_text {
  width: 100%;
  height: 45%;
  background-color: #FFF;
  color: #333;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding: 20px;
  /*display: flex;*/
//flex-direction: column; //align-content: space-between;
}
</style>