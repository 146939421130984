<template>
  <div>
    <div class="bg-white radius-20 pa-20">

      <ul class="payment" v-if="item.sl_state_code === 'SA00200003' && detail.payment_mthd_list">
        <li class="flex-row justify-space-between align-center mt-10"
            @click="onPaymentList">
          <div class="mr-10">
            <p class="size-px-12 mb-5" style="opacity: 0.3;">결제 수단 선택</p>
            <!-- 결제 수단 선택 -->
            <p class="size-px-14 font-weight-600">
              {{ item_payment.payment_money_code ? item_payment.payment_money_name : '결제수단을 선택하세요' }}</p>
          </div>
          <p><i class="icon icon-arrow-right size-px-16" style="opacity: 0.3;"></i></p>
        </li>
        <li v-if="item_payment.payment_money_code === 'PM01100200'"
            class="flex-row justify-space-between align-center mt-10"
            @click="is_on_credit = true"
        >
          <div class="mr-10">
            <p class="size-px-12 mb-5" style="opacity: 0.3;">신용카드 선택</p><!-- 신용카드 선택 -->
            <p v-if="!item_credit.payment_mthd_name"
               class="size-px-16"
            >결제카드를 선택하세요</p>
            <div class="flex-row align-center" v-else>
              <img src="@/assets/image/cartel-cover-thumb.png" alt="credit_img"
                   style="width: 20px; height: 20px; border-radius: 50%">
              <p class="size-px-16 ml-10">
                {{ item_credit.payment_mthd_name }} ({{
                  item_credit.payment_mthd_number.slice(-4).replaceAll('X', '')
                }})
              </p>
            </div>
          </div>
          <p><i class="icon icon-arrow-right size-px-15" style="opacity: 0.3;"></i></p>
        </li>
        <li v-if="item_payment.payment_money_code"
            class="flex-column mt-10"
        >
          <div class="flex-row justify-space-between">
            <p class="size-px-12 mb-5" style="opacity: 0.3;">수량 선택</p><!-- 수량 선택 -->
            <div class="flex-row align-center">
              <img src="@/assets/image/cartel-cover-thumb.png" alt="credit_img"
                   style="width: 20px; height: 20px; border-radius: 50%">
              <p class="size-px-14 font-weight-600 ml-10">
                {{ bigTimes(item_payment.payment_amount_quantity, quantity) | toFixed(8) }}
                {{
                  item_payment.payment_money_code !== 'PM01100200' && item_payment.payment_money_code !== 'PM01100300' ? item_payment.payment_money_name : '원'
                }}
              </p>
            </div>
          </div>
          <div class="flex-row align-center justify-center mt-15">
            <button class="btn_circle mr-10"
                    :disabled="quantity === 1"
                    :class="{'btn_disabled' : quantity === 1}"
                    @click="quantity--">
              <font-awesome-icon :icon="['fas', 'minus']"/>
            </button>
            <p class="size-px-15 font-weight-600">{{ quantity }}</p>
            <button class="btn_circle ml-10" @click="quantity++">
              <font-awesome-icon :icon="['fas', 'plus']"/>
            </button>
          </div>
        </li>
        <li v-if="item_payment.payment_money_code"
            class="mt-10"
        >
          <div class="flex-row align-center justify-space-between">
            <span class="size-px-13"
                  v-if="item_payment.payment_money_code === 'PM01100200' || item_payment.payment_money_code === 'PM01100300'">
              최종 결제 금액
            </span><!-- 최종 결제 금액 -->
            <span class="size-px-13" v-else>최종 결제 수량</span><!-- 최종 결제 수량 -->
            <span class="size-px-13 font-weight-600" style="color: #3E47B7">
            {{ bigTimes(item_payment.last_payment_amount_quantity, quantity) | toFixed(8) }}
            {{
                item_payment.payment_money_code !== 'PM01100200' && item_payment.payment_money_code !== 'PM01100300'
                    ? item_payment.payment_money_name
                    : '원'
              }}
                        </span>
          </div>
          <hr class="mt-10 mb-10" style="border: 1px solid rgba(255, 255, 255, 0.05)">
          <div class="flex-row align-center justify-space-between mb-5">
            <span class="size-px-12" style="opacity: 0.7"
                  v-if="item_payment.payment_money_code === 'PM01100200' || item_payment.payment_money_code === 'PM01100300'">
              결제 금액
            </span><!-- 결제 금액 -->
            <span class="size-px-12" style="opacity: 0.7" v-else>결제 수량</span>
            <!-- 결제 수량 -->
            <span class="size-px-12 font-weight-600" style="opacity: 0.7">
            <span>{{ bigTimes(item_payment.payment_amount_quantity, quantity) }}</span>
            {{
                item_payment.payment_money_code !== 'PM01100200' && item_payment.payment_money_code !== 'PM01100300' ? item_payment.payment_money_name : '원'
              }}
          </span>
          </div>
          <div class="flex-row align-center justify-space-between mb-5"
               v-if="item_payment.payment_money_code === 'PM01100200'">
            <span class="size-px-12" style="opacity: 0.7">부가세</span><!-- 부가세 -->
            <span class="size-px-12 font-weight-600" style="opacity: 0.7">
            {{ item_payment.vat * quantity }}원
          </span>
          </div>
          <div class="flex-row align-center justify-space-between">
            <span class="size-px-12" style="opacity: 0.7">기본 할인</span><!-- 기본 할인 -->
            <span class="size-px-12 font-weight-600" style="opacity: 0.7">
            {{
                item_payment.dc_amount_quantity
                    ? item_payment.dc_method_code === 'PM00700002' && item_payment.payment_money_code !== 'PM01100300'
                        ? (bigTimes(bigTimes(item_payment.payment_amount_quantity, item_payment.dc_amount_quantity / 100), quantity))
                        : bigTimes(item_payment.dc_amount_quantity, quantity)
                    : 0
              }}
            {{
                item_payment.payment_money_code !== 'PM01100200' && item_payment.payment_money_code !== 'PM01100300' ? item_payment.payment_money_name : '원'
              }}
            </span>
          </div>
        </li>
      </ul>
      <button class="btn_blue" v-if="item.sl_state_code === 'SA00200003' && detail.payment_mthd_list"
              @click="onPin">NFT 구매하기
      </button>
      <div
          v-if="item.sl_state_code !== 'SA00200003' || !detail.payment_mthd_list"
          class="pa-10 flex-column justify-center items-center"
      >
        <div><img :src="require('@/assets/image/list_none.png')" alt="이력없음" style="width: 120px"></div>
        <div class="mt-20 size-px-14 color-bbb">{{ sale_state }}</div>
      </div>
      <Popup_select
          v-if="is_on_payment"
          @cancel="is_on_payment = false"
      >
        <template
            v-slot:title
        >{{ $language.point_result.title_payment_type }}
        </template>
        <template
            v-slot:list
        >
          <li
              v-for="(payment, p_index) in list_purchase"
              :key="'payment_' + p_index"
              class="ptb-10 mb-10"
              @click="setPayment(payment)"
          >{{ payment.payment_money_name }}
          </li>
        </template>
      </Popup_select>
      <MyCredit
          v-if="is_on_credit"
          :user="user"
          @click="setCredit"
          @cancel="is_on_credit = false"
      ></MyCredit>
    </div>
  </div>

</template>

<script>
import Big from "big.js";
import MyCredit from "@/view/Mypage/MyCredit";
import Popup_select from "@/view/Layout/PopupSelect";

export default {
  name: 'SalePayment'
  , props: ['user', 'item', 'detail']
  , components: {Popup_select, MyCredit}
  , data() {
    return {
      item_payment: []
      , item_credit: []
      , is_on_payment: false
      , is_on_credit: false
      , quantity: 1
    }
  }
  , computed: {
    sale_state: function () {
      let t = ''
      switch (this.item.sl_state_code) {
        case 'SA00200001':
          t = '판매 예정입니다.'
          break;
        case 'SA00200002':
          t = '판매 예정입니다.'
          break;
        case 'SA00200003':
          if (!this.detail.payment_mthd_list)
            t = '등록된 결제수단이 없습니다.'
          break;
        case 'SA00200004':
          t = '판매가 종료되었습니다.'
          break;
        case 'SA00200005':
          t = '판매가 종료되었습니다.'
          break;
      }
      return t
    }
    , list_purchase: function () {
      return this.detail.payment_mthd_list.filter((item) => {
        if (item.payment_money_code != 'PM01100300') {
          return item
        }
      })
    }
  }
  , methods: {
    onPaymentList: function (type) {
      console.log(type, 'type')
      this.is_on_payment = type
    }
    , setPayment: function (item) {
      this.item_payment = item
      this.is_on_payment = false
      if (item.payment_money_code == 'PM01100300') {
        this.quantity = 1
      }
      // this.getPaymentType()
    }
    , setCredit: function (credit) {
      console.log(credit, 'credit')
      this.item_credit = credit
      this.is_on_credit = false
    }
    , onPin: function () {
      if (!this.item_payment.payment_money_code) {
        this.$bus.$emit('notify', {type: 'error', message: this.$language.launchpad.select_payment_input})
        return
      }
      if (this.item_payment.payment_money_code === 'PM01100200' && !this.item_credit.payment_mthd_number) {
        this.$bus.$emit('notify', {type: 'error', message: this.$language.launchpad.select_card_input})
        return
      }
      if (Number(this.quantity) < 1) {
        this.$bus.$emit('notify', {type: 'error', message: '수량을 정확히 입력하세요'})
        return
      }
      this.$bus.$off('pinCallback')
      this.$bus.$on('pinCallback', (type, pin) => {
        console.log(type, pin)
        this.pinnumber = pin
        this.postBuyNft()
      })
      this.$bus.$emit('onPin', 'check')
    }
    , bigTimes: function (a, b) {
      a = Number(a)
      b = Number(b)
      let price = new Big(a)
      return String(price.times(b))
    }
    , bigMinus: function (a, b) {
      a = new Big(Number(a))
      b = new Big(Number(b))
      return a.minus(b)
    }
    , postBuyNft: async function () {
      try {
        this.$bus.$emit('on', true)
        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_sale_nft_purchase
          , data: {
            member_number: this.user.member_number
            , cartl_nft_sl_number: this.item.cartl_nft_sl_number
            , pinnumber: this.pinnumber
            , purchase_quantity: this.quantity
            , sl_price_quantity: this.item_payment.payment_amount_quantity
            , payment_money_code: this.item_payment.payment_money_code
            , payment_mthd_number: this.item_credit.payment_mthd_number
            , last_payment_amount_quantity: this.bigTimes(this.item_payment.last_payment_amount_quantity, this.quantity)
            , payment_amount_quantity: this.bigTimes(this.item_payment.payment_amount_quantity, this.quantity)
            , dc_amount_quantity: this.item_payment.dc_method_code === 'PM00700002'
                ? (this.bigTimes(this.bigTimes(this.item_payment.payment_amount_quantity, this.item_payment.dc_amount_quantity / 100), this.quantity))
                : this.bigTimes(this.item_payment.dc_amount_quantity, this.quantity)
            , vat: Number(this.bigTimes(this.item_payment.vat, this.quantity))
          }
          , type: true
        })

        if (result.success) {
          console.log('result', result)
          if (result.result.data.resultinfo.result_div_cd === 'ERR') {
            this.$bus.$emit('notify', {type: 'error', message: result.result.data.resultinfo.result_msg})
            return
          }
          if (result.result.data.d.cartl_nft_purchase_list) {
            await this.$router.push({
              name: 'PurchaseNft',
              params: {
                items: result.result.data.d
                , nft_info: this.item
              }
            })
          }
        } else {
          throw result.message
        }
      } catch (e) {
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.$bus.$emit('on', false)
      }
    }
    , setItem: function (item) {
      console.log('setItem', item)
      this.item_select_coin = item
      this.is_on_payment = false
      this.blockchain_div_code = item.blockchain_div_code
      if (this.card_type == 'nft') {
        this.toBuyNft()
      }
    }
  }
  , watch: {
    'detail': {
      handler: function () {
        this.item_payment = []
      }
    }
  }
}

</script>

<style scoped>
p span {
  color: #333;
}

.payment li {
  border: 1px solid #E9E9E9;
  border-radius: 8px;
  padding: 14px;
}

.btn_blue {
  width: 100%;
  margin-top: 20px;
  padding: 12px;
  color: white;
  background: #293097;
  border-radius: 25px;
  font-size: 16px;
  text-align: center;
}

.bottom_btn {
  background: #181A39;
  width: 100%;
  z-index: 10;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px 30px 0 0;
}

.bottom_btn button {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.btn_circle {
  width: 30px;
  height: 30px;
  border: 1px solid #E9E9E9;
  border-radius: 100%;
  padding: 5px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_disabled {
  background: #F3F3F3;
  color: #BBBBBB;
}
</style>