<template>
  <div class="pa-20 overflow-y-auto bg-white radius-20">
    <div ref="width_offset">
      <div class="flex-row">
        <img :src="img_list" alt="nft"
             @error="$bus.$emit('onErrorImage', $event)"
             style="width: 250px; height: 250px; border-radius: 20px;"
        >
        <div class="pa-10-20 flex-column text-left justify-space-between" style="width: 59%">
          <button class="color-gray size-px-12 text-right mb-5"
                  @click="$router.push({ name: 'mafia049', params: { idx: item.cartl_number, from: $route.name}} )"
          >
            <span class="size-px-14">카르텔 방문하기</span>
            <i class="icon-arrow-right ma-5"></i>
          </button>
          <div>
            <p class="font-weight-600" style="color: #555">
              {{ detail.sl_startdt ? (detail.sl_startdt).slice(2, 10) : '' }} ~
              {{ detail.sl_enddate ? (detail.sl_enddate).slice(2, 10) : '' }}
            </p>
            <h3 class="mt-5" style="word-break: break-all">{{ detail.nft_sl_name }}</h3>
            <p class="mt-5" style="color: #BBB;">{{ detail.cartl_name }}</p>
            <p class="mt-5 size-px-13" style="color: #3E47B7" v-if="item.sl_state_code === 'SA00200003'">판매가
              <!-- 판매가 -->
              <span class="size-px-16 font-weight-600">&#x20A9;{{ detail.sl_basic_price }}</span>
            </p>
          </div>
          <hr class="mt-20 mb-20" style="border: 1px solid #F3F3F3;">
          <div>
            <div class="flex-row justify-space-between">
              <p class="size-px-13 color-gray">현재
                <span class="size-px-16" style="font-weight: 700; color: #3E47B7;">
                  {{ detail.sl_compl_quantity }}개
                </span>
                판매되었습니다.
              </p>
              <div>
                <p class="size-px-13 color-gray">
                  <span style="color: #3E4787;">{{ detail.sl_compl_quantity }}</span> / {{ detail.sl_quantity }}
                </p>
              </div>
            </div>
          </div>
          <ProgressBar
              :diameter="200"
              :completed-steps="completedSteps"
              :total-steps="totalSteps"
              :show-tip="false"
              :innerStrokeColor="'#F3F3F3'"
              :startColor="'#3E47B7'"
              :stopColor="'#3E47B7'"
              :width="width"
              :height="15"
          >
          </ProgressBar>
        </div>
      </div>


      <div class="mt-20" style="color: #888;">
        <p class="size-px-16 text-left font-weight-500" style="color: #171C61;">구매 안내 및 권리 / 유의 사항</p>
        <ul class="size-px-13" style="margin-top: 14px">
          <li>· NFT 신용카드 구매 시 부가세가 발생합니다.</li>
          <li>· 코인으로 구매 시 24시간 기준 00시에 수량 변동이 발생합니다.</li>
          <li>· 구매가 완료된 NFT의 경우 환불이 불가능합니다.</li>
          <li>· NFT는 정해진 시간에만 구입 가능하며 이후에는 해당 카르텔을 통해 구입이 가능합니다.</li>
          <li>· 구매한 NFT는 컬렉션에서 확인이 가능합니다.</li>
        </ul>
      </div>
      <hr class="mt-20 mb-20" style="border: 1px solid #F3F3F3">
      <div class="scrollBar" style="height: 450px; margin-bottom: 70px;">
        <p class="size-px-13 mb-10 text-left">
          {{ detail.sl_guide }}
        </p>
        <img v-for="img in img_contents_list" :key="img.img_indexnumber" :src="img.img_file_path" alt="nft_detail" style="width: 100%;">
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "vue2-progress-bar";

export default {
  name: "SaleDetail"
  , props: ['user', 'item', 'show']
  , components: {
    ProgressBar,
  }
  , data() {
    return {
      completedSteps: 0
      , totalSteps: 0
      , width: 500
      , quantity: 1
      , items: []
      , card_type: ''
      , item_select_coin: {
        sl_coin_token_name: ''
      }
      , is_detail: false
      , detail: []
      , item_payment: []
      , item_credit: []
      , img_list: ''
      , img_contents_list: ''
    }
  }
  , methods: {
    setProgress() {
      const val = this.$refs.width_offset.offsetWidth * 0.52
      this.completedSteps = Number(this.detail.sl_compl_quantity)
      this.totalSteps = Number(this.detail.sl_quantity)

      if (!val || isNaN(val)) {
        return false;
      }

      this.width = parseInt(val);
    }
    , setItem: function (item) {
      this.item_select_coin = item
      this.is_on_payment = false
      this.blockchain_div_code = item.blockchain_div_code
      if (this.card_type == 'nft') {
        this.toBuyNft()
      }
    }
    , getNftDetail: async function () {
      this.img_list = ''
      this.img_contents_list = ''
      let detail_url
      if (this.item.sl_state_code === 'SA00200003') {
        detail_url = this.$api_url.api_path.get_sale_nft_detail
      } else {
        detail_url = this.$api_url.api_path.get_not_sale_nft_list
      }
      try {
        this.$bus.$emit('on', true)
        const result = await this.$request.init({
          method: 'post'
          , url: detail_url
          , data: {
            member_number: this.user.member_number
            , cartl_nft_sl_number: this.item.cartl_nft_sl_number
          }
          , type: true
        })

        if (result.success) {
          if (result.result.data.resultinfo.result_div_cd === 'ERR') {
            this.$bus.$emit('notify', {type: 'error', message: result.result.data.resultinfo.result_msg})
            return
          }
          this.detail = result.result.data.d
          this.detail.cartl_nft_sl_img_list.forEach((item) => {
            switch (item.exposure_position_code) {
              case 'SA00300003' :
                this.img_list = item.sl_img_partial_list[0].img_file_path
                break;
              case 'SA00300004' :
                this.img_contents_list = item.sl_img_partial_list
                break;
            }
          })
          this.$emit('setDetail', this.detail)
          this.setProgress()
        } else {
          throw result.message
        }
      } catch (e) {
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.$bus.$emit('on', false)
      }
    }
  }
  , watch: {
    'item': {
      handler: function () {
        this.getNftDetail()
      }
    }
  }
}
</script>

<style scoped>
li {
  margin-bottom: 6px;
  color: #333;
}

.btn_blue {
  width: 100%;
  margin-top: 20px;
  padding: 12px;
  color: white;
  background: #293097;
  border-radius: 25px;
  font-size: 16px;
  text-align: center;
}

.bottom_btn {
  background: #181A39;
  width: 100%;
  z-index: 10;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px 30px 0 0;
}

.bottom_btn button {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.btn_circle {
  width: 30px;
  height: 30px;
  background: #293097;
  border-radius: 100%;
  padding: 5px;
  color: white;
}
.scrollBar {
  overflow-y: scroll;
  padding-right: 5px;
}

/* 아래의 모든 코드는 영역::코드로 사용 */
.scrollBar::-webkit-scrollbar {
  width: 5px;  /* 스크롤바의 너비 */
}

.scrollBar::-webkit-scrollbar-thumb {
  height: 30%; /* 스크롤바의 길이 */
  background: #F3F3F3; /* 스크롤바의 색상 */

  border-radius: 10px;
}

.scrollBar::-webkit-scrollbar-track {
  background: #fff;  /*스크롤바 뒷 배경 색상*/
}
</style>