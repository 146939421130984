<template>
	<Popup_select
		@cancel="$emit('cancel')"
	>
		<template
			v-slot:title
			class="justify-space-between"
		>{{ $language.common.credit_select }}</template>
		<template
			v-slot:list
		>
			<template
				v-if="items_credit.length > 0"
			>
				<li
					v-for="(credit, index) in items_credit"
					:key="'item_' + index"
					style="padding: 10px 0;"
					@click="setCredit(credit)"
				>
					{{ credit.payment_mthd_name }}
					({{ credit.payment_mthd_reg_number.slice(-4).replaceAll('X', '') }})
					<label
						v-if="credit.representation_payment_fg == 'Y'"
						class="label label-primary"
					> {{ $language.mypage.credit.credit_setup_name }}</label>
				</li>
			</template>
			<li
				v-else
				class="ptb-50 text-center"
			>{{ $language.point.error_not_credit}}<!-- 등록된 신용카드가 없습니다.--></li>
			<li
				class="mt-30"
			>
				<div class="">
					<button
						@click="toCredit"
						class="btn btn-primary"
					>+ {{ $language.mypage.credit_regist }}</button>
				</div>
			</li>
		</template>
	</Popup_select>
</template>

<script>
import Popup_select from "@/view/Layout/PopupSelect";
export default {
	name: 'MyCredit'
	,
	components: {Popup_select},
	props: ['user']
	, data: function(){
		return {
			items_credit: []
			, item_search: {
				page: 1
				, list_cnt: 10
			}
		}
	}
	, methods: {
		getCreditList: async function(){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_credit_list
					, data: {
						member_number: this.user.member_number
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
					, type: true
				})
				if(result.success){
					this.items_credit = result.data.payment_mthd_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setCredit: function(item){
			this.$emit('click', item)
		}
		, toCredit: function(){
			this.$bus.$emit('to', { name: 'mafiaCreditRegist'})
		}
	}
	, created() {
		this.getCreditList()
	}
}
</script>